export default {
  SET_USER: 'SET_USER',
  IS_LOGIN_IN: 'IS_LOGIN_IN',
  LOGIN_IN_ERROR: 'LOGIN_IN_ERROR',
  IS_USER_LOADING: 'IS_USER_LOADING',
  LOG_OUT_USER: 'LOG_OUT_USER',
  SET_CHOICE_OF_AGENCIES: 'SET_CHOICE_OF_AGENCIES',
  SET_AUTH_TYPE: 'SET_AUTH_TYPE',
  SET_AGENCY_COMPANY: 'SET_AGENCY_COMPANY',
  SET_SITE_PUBLICITY: 'SET_SITE_PUBLICITY',
  SET_PUBLICITY_LOADING: 'SET_PUBLICITY_LOADING',
  SET_AGENT_AUTH_USER: 'SET_AGENT_AUTH_USER',
  SWITCH_COMPANY: 'SWITCH_COMPANY',
  SET_SITE_OPTIONS: 'SET_SITE_OPTIONS',
  SET_FAVORITE_USERS: 'SET_FAVORITE_USERS',
  SET_LOYALTIES: 'SET_LOYALTIES',
  SET_CURRENCY: 'SET_CURRENCY',
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
}
