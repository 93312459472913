import constants from './constants'
import { allUsersAction, setUsers } from '../users/userAction'
import {
  closeAllModals,
  openLoginWithCompanyModal,
} from '../modals/modalAction'
import {
  fetchLogInUser,
  fetchGetUser,
  fetchUpdateUserInfo,
  fetchUpdatePassword,
  getSitePublicity,
  fetchActivateUser,
  fetchForgotPassword,
  fetchChangePassword,
  switchCompany,
} from '../../repositories/auth'
import moment from 'moment'
import {
  resetSearchParameters,
  resetSearchTravellers,
} from '../search/searchAction'
import axios from 'axios'
import storage from 'local-storage-fallback'

export const loginAction = (user, userStatus) => async (dispatch) => {
  let modalCloseExcepts = []
  modalCloseExcepts.push('addMobileModal')

  try {
    dispatch(isLogginIn(true))
    const data = await fetchLogInUser(user)

    // Reset search parameters
    await dispatch(resetSearchParameters())

    // Set admin data for shared login.
    storage.setItem(
      'store',
      JSON.stringify({
        name: data.name,
        token: data.access_token,
        refresh_token: data.refresh_token,
        companyId: data.companyId,
        expiry: data.expiry,
      })
    )

    storage.setItem(
      'user',
      JSON.stringify({ userEmail: user.email, userStatus })
    )

    if (data.url) {
      storage.setItem('url', data.url)
    }

    await dispatch(
      setAccessToken(
        data.access_token,
        moment().add(data.expiry, 'minutes').format()
      )
    )

    await dispatch(getUserAction())
    await dispatch(allUsersAction())
    await dispatch(fetchPublicity())
  } catch (error) {
    modalCloseExcepts.push('loginModal') // Add exception for "close all modals" action if login failed

    console.log(error)

    // User exists on multiple companies - open login modal
    if (error.response?.data?.requireCompanySelection) {
      modalCloseExcepts = []
      modalCloseExcepts.push('loginWithCompanyModal')
      dispatch(
        openLoginWithCompanyModal({
          email: user.email,
          password: user.password,
          agency: user.agency,
          companies: error.response.data.companies,
        })
      )
      return
    }

    // Invalid login
    if (error.response?.status === 400) {
      dispatch({
        type: constants.LOGIN_IN_ERROR,
        payload: error.response?.data || 'error',
      })
      return
    }

    // Status 418 means we need to supply agency. Data contains a list of possible agencies.
    if (error.response?.status === 418) {
      dispatch({
        type: constants.SET_CHOICE_OF_AGENCIES,
        payload: error.response.data,
      })
      return
    }

    throw error
  } finally {
    dispatch(closeAllModals(modalCloseExcepts))
    dispatch(isLogginIn(false))
  }
}

export const getUserAction = () => async (dispatch, getState) => {
  const { authType } = getState().auth
  try {
    dispatch(isLoading(true))
    const user = await fetchGetUser('get-user-key')
    dispatch(setUser(user))
    dispatch(isLoading(false))
  } catch (error) {
    const isCancelled = axios.isCancel(error)
    dispatch(isLoading(false))
    if (!isCancelled && authType !== 'viaAgent') {
      dispatch(logOutUser())
    } else if (!isCancelled) {
      dispatch(setAgentAuthUser(null, 'agent'))
    }
    throw error
  }
}

export const updateUserAction = (data) => async (dispatch) => {
  try {
    dispatch(isLoading(true))
    const response = await fetchUpdateUserInfo(data)
    dispatch(resetSearchTravellers())
    return response
  } catch (e) {
    throw e
  } finally {
    dispatch(isLoading(false))
  }
}

export const updatePasswordAction = (data) => async (dispatch) => {
  try {
    dispatch(isLoading(true))
    return await fetchUpdatePassword(data)
  } catch (e) {
    throw e
  } finally {
    dispatch(isLoading(false))
  }
}

export const registerAction = () => async () => {
  // const { firstName, lastName, email, mobile, password } = form;

  try {
    //const registration = await fetchRegisterUser(user)
  } catch (error) {
    console.log(error)
  }
}

export const activateUserAction = (data) => async (dispatch) => {
  dispatch(isLoading(true))
  try {
    await fetchActivateUser(data)

    try {
      await dispatch(
        loginAction({ email: data.email, password: data.password })
      )
    } catch (e) {
      console.log(e)
    }
  } catch (e) {
    throw e
  } finally {
    dispatch(isLoading(false))
  }
}

export const changePasswordAction = (data) => async (dispatch) => {
  dispatch(isLoading(true))
  try {
    await fetchChangePassword({ code: data.code, password: data.password })
    await dispatch(loginAction({ email: data.email, password: data.password }))
  } catch (e) {
    throw e
  } finally {
    dispatch(isLoading(false))
  }
}

export const forgotPasswordAction = (email) => async (dispatch) => {
  dispatch(isLoading(true))
  try {
    await fetchForgotPassword(email)
  } catch (e) {
    throw e
  } finally {
    dispatch(isLoading(false))
  }
}

export const logOutUser =
  (clearStorage = false) =>
  (dispatch) => {
    let user = storage.getItem('user')
    if (clearStorage) {
      storage.clear()
    }
    storage.setItem('user', user)

    dispatch({ type: constants.LOG_OUT_USER })
  }

export const switchCompanyAction = (companyId) => async (dispatch) => {
  try {
    dispatch(isLogginIn(true))

    let data = await switchCompany(companyId)
    await dispatch(relogin(data))
  } catch (e) {
    console.log(e)
  } finally {
    dispatch(isLogginIn(false))
  }
}

export const relogin = (authData) => async (dispatch) => {
  let user = storage.getItem('user')
  //storage.clear()
  storage.setItem('user', user)

  await dispatch({ type: constants.LOG_OUT_USER })
  // Reset search parameters
  await dispatch(resetSearchParameters())

  // Set admin data for shared login.
  storage.setItem(
    'store',
    JSON.stringify({
      name: authData.name,
      token: authData.access_token,
      refresh_token: authData.refresh_token,
      companyId: authData.companyId,
      expiry: authData.expiry,
    })
  )

  await dispatch(
    setAccessToken(
      authData.access_token,
      moment().add(authData.expiry, 'minutes').format()
    )
  )

  await dispatch(getUserAction())
  await dispatch(allUsersAction())
  await dispatch(fetchPublicity(true))
}

export const setAgentAuthUser =
  (token, authType = 'viaAgent') =>
  async (dispatch) => {
    dispatch(isLoading(true))
    await dispatch({
      type: constants.SET_AGENT_AUTH_USER,
      payload: { token, authType },
    })

    await dispatch(resetSearchParameters())
    await dispatch(allUsersAction(false))
    await dispatch(getUserAction())
    await dispatch(fetchPublicity(true))

    dispatch(isLoading(false))
  }

//
const setUser = (user) => ({
  type: constants.SET_USER,
  payload: user,
})

export const setAuthType = (type) => async (dispatch) => {
  await dispatch({
    type: constants.SET_AUTH_TYPE,
    payload: type,
  })
  await dispatch(resetSearchTravellers())
  await dispatch(getUserAction())
}

export const setAgencyCompany = (company) => async (dispatch) => {
  dispatch({
    type: constants.SET_AGENCY_COMPANY,
    payload: company,
  })

  await dispatch(resetSearchTravellers())
  await dispatch(setUsers([]))
  await dispatch(allUsersAction())
  await dispatch(getUserAction())
}

export const setPublicityLoading = (isLoading) => ({
  type: constants.SET_PUBLICITY_LOADING,
  payload: !!isLoading,
})

export const setSiteOptions = (options) => ({
  type: constants.SET_SITE_OPTIONS,
  payload: options,
})

export const setFavoriteUsers = (favorites) => async (dispatch) => {
  dispatch({
    type: constants.SET_FAVORITE_USERS,
    payload: favorites,
  })
}

/**
 * fetch Agency site publicity
 *
 * @returns {(function(*): Promise<void>)|*}
 */
export const fetchPublicity = (skipLoadingIndicator) => async (dispatch) => {
  try {
    if (!skipLoadingIndicator) dispatch(setPublicityLoading(true))
    await new Promise((resolve) => setTimeout(resolve, 1000))
    const data = await getSitePublicity()

    dispatch(
      setSiteOptions({
        publicity: data.publicity || 'private',
        disabled: data.disabled,
        disabledPublic: data.disabledPublic,
        countryPhone: data.countryPhone,
        currency: data.currency,
        trainSearch: data.trainSearch,
        showAddressDetails: data.showAddressDetails,
        showBirthDate: data.showBirthDate,
        agencyBanners: data.agencyBanners,
        banners: data.banners,
        supportTelephone: data.supportTelephone,
        hideFooter: data.hideFooter,
        defaultPath: data.defaultPath,
        backgroundImage: data.backgroundImage,
        backgroundColor: data.backgroundColor,
        defaultLang: data.defaultLang,
        showDisabledLinks: data.showDisabledLinks,
        agencyTitle: data.agencyTitle,
        messages: data.messages,
        skipConfirmationEmail: data.skipConfirmationEmail,
        requireMobile: data.requireMobile,
        addresses: data.addresses,
        serviceFee: data.serviceFee,
        useAvropa: data.useAvropa,
        restrictedTransfers: data.restrictedTransfers,
        showCalendarInHeader: data.showCalendarInHeader,
        allowAdditionalRecipient: data.allowAdditionalRecipient,
        hideCreditcardFees: data.hideCreditcardFees,
        hideCorporateCodes: data.hideCorporateCodes,
        enableAnyFlightCabin: data.enableAnyFlightCabin,
        encloseCostfieldValues: data.encloseCostfieldValues,
        enableMultiStop: data.enableMultiStop || false,
        forceB2BWalletForLeisureHotels: data.forceB2BWalletForLeisureHotels,
        useLogoForMapAddresses: data.useLogoForMapAddresses,
        currencies: data.currencies,
        allowCurrencySelection: data.allowCurrencySelection,
        loginUrl: data.loginUrl,
        registerUrl: data.registerUrl,
        showGroupTravelInHeader: data.showGroupTravelInHeader,
        policyReasons: data.policyReasons,
        isSSOEnabled: data.isSSOEnabled,
        allowRegistration: data.allowRegistration,
        maximumAllowedTravelClasses: data.maximumAllowedTravelClasses,
      })
    )

    if (data.defaultSelectedCurrency) {
      dispatch(setCurrency(data.defaultSelectedCurrency))
    }
  } catch (error) {
    if (!skipLoadingIndicator) dispatch(setPublicityLoading(false))
    throw error
  } finally {
    if (!skipLoadingIndicator) dispatch(setPublicityLoading(false))
  }
}

export const setCurrency = (currency) => ({
  type: constants.SET_CURRENCY,
  payload: currency,
})

export const isLoading = (loading) => ({
  type: constants.IS_USER_LOADING,
  payload: loading,
})

export const isLogginIn = (loading) => ({
  type: constants.IS_LOGIN_IN,
  payload: loading,
})

export const setLogInError = (error) => (dispatch) => {
  dispatch({ type: constants.LOGIN_IN_ERROR, payload: error })
}

export const setLoyaltyPrograms = (programs) => ({
  type: constants.SET_LOYALTIES,
  payload: programs,
})

export const setAccessToken = (jwtToken, jwtExpires) => ({
  type: constants.SET_ACCESS_TOKEN,
  payload: { jwtToken, jwtExpires },
})
